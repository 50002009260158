import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, StyleSheet, SafeAreaView, Linking, ActivityIndicator, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { BarChart } from 'react-native-chart-kit';
import { Dimensions } from 'react-native';

const screenWidth = Dimensions.get('window').width;

const InfluencerAnalysis = ({ route, navigation }) => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Get PlatformUsername from the route params
  const { PlatformUsername } = route.params;

  useEffect(() => {
    // Fetch data using the PlatformUsername from route params
    fetch(`https://collabbayadmin.com/APIControllers/SocialProfileDataAPI.php?PlatformUsername=${PlatformUsername}`)
      .then(response => response.json())
      .then(data => {
        // Check for error or unexpected structure
        if (data && !data.error && data.instagramName) {
          setProfileData(data);
        } else {
          console.error('Unexpected API structure or error:', data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [PlatformUsername]); // Trigger fetch when PlatformUsername changes

  const handleBackPress = () => {
    navigation.goBack();
  };

  if (loading) {
    return (
      <SafeAreaView style={styles.safeArea}>
        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>
        <ActivityIndicator size="large" color="#FDF05D" style={{ marginTop: 25 }} />
      </SafeAreaView>
    );
  }

  if (!profileData) {
    return (
      <SafeAreaView style={styles.safeArea}>
        {/* Back button */}
        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>

        {/* Error message */}
        <View style={styles.noDataContainer}>
          {/* Instagram link (if available) */}
          <Text style={styles.instagramLinkText}>
            Influencer Profile{' '}
            <Text
              style={styles.instagramLink}
              onPress={() => Linking.openURL(`https://www.instagram.com/${PlatformUsername}`)}
            >
              Visit Instagram Profile
            </Text>
          </Text>
        </View>
      </SafeAreaView>
    );
  }

  // Metrics for the user
  const metrics = [
    { label: 'Followers', value: profileData.followers.toLocaleString() },
    { label: 'Engagement Rate', value: `${(parseFloat(profileData.engagementRate) * 100).toFixed(2)}%` },
    { label: 'Avg Likes', value: Math.round(profileData.avgLikes).toLocaleString() },
    { label: 'Avg Comments', value: Math.round(profileData.avgComments).toLocaleString() },
  ];

  // Calculate Posts Frequency
  const postFrequency = profileData.avgPostsPerDay > 0 ? Math.round(1 / profileData.avgPostsPerDay) : 'N/A';

  // Bar Chart Data
  const barData = {
    labels: ['Avg Likes', 'Avg Comments'],
    datasets: [
      {
        data: [Math.round(profileData.avgLikes), Math.round(profileData.avgComments)],
      },
    ],
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView contentContainerStyle={styles.container}>

        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>

        {/* Profile Section */}
        <View style={styles.profileContainer}>
          <Text style={styles.name}>@{profileData.instagramName}</Text>
          <Text style={styles.description}>{profileData.description}</Text>
        </View>

        {/* Themes Section (Hashtags) */}
        <View style={styles.themesContainer}>
          <Text style={styles.sectionTitle}>Themes</Text>
          <View style={styles.hashtags}>
            {profileData.hashtags.map((hashtag, index) => (
              <Text key={index} style={styles.hashtag}>
                {hashtag}
              </Text>
            ))}
          </View>
        </View>

        {/* Metrics Section */}
        <View style={styles.metricsContainer}>
          {metrics.map((metric, index) => (
            <View key={index} style={styles.metricCard}>
              <Text style={styles.metricValue}>{metric.value}</Text>
              <Text style={styles.metricLabel}>{metric.label}</Text>
            </View>
          ))}
        </View>

        {/* Posts Info */}
        <View style={styles.postsContainer}>
          <Text style={styles.sectionTitle}>Number of Posts</Text>
          <Text style={styles.postsCount}>{profileData.posts.toLocaleString()}</Text>
          <Text style={styles.postsFrequency}>Posts every {postFrequency} days</Text>
        </View>

        <View
      style={{
        backgroundColor: 'lightgray',
        borderRadius: 10,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Ionicons
        name="help-circle"
        size={24}
        color="gray"
        style={{ marginBottom: 5 }}
      />
      <Text
        style={{
          fontSize: 10,
          textAlign: 'center',
          padding: 5,
        }}
      >
        Some posts may have insights hidden which can affect engagement metrics
        analysis. Don't worry, it's normal! Click the link at the bottom of the page to see the creators profile. 
      </Text>
    </View>
        {/* Bar Chart Section */}
        <View style={styles.chartContainer}>
          <BarChart
            data={barData}
            width={screenWidth - 30}
            height={220}
            yAxisSuffix=""
            yAxisInterval={1}
            fromZero={true}
            chartConfig={{
              backgroundColor: '#fff',
              backgroundGradientFrom: '#fff',
              backgroundGradientTo: '#fff',
              decimalPlaces: 0,
              color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
              labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
              style: {
                borderRadius: 16,
              },
              propsForLabels: {
                fontFamily: 'Helvetica',
                fontSize: 12,
              },
            }}
            style={styles.barChart}
          />
        </View>

        {/* Instagram Link */}
        <View style={styles.instagramLinkContainer}>
          <Ionicons name="logo-instagram" size={24} color="#FDF05D" />
          <Text
            style={styles.instagramLink}
            onPress={() => Linking.openURL(`https://www.instagram.com/${profileData.instagramName}`)}
          >
            Visit Instagram Profile
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#FFF',
  },
  container: {
    flexGrow: 1,
    alignItems: 'center',
    backgroundColor: '#FFF',
    padding: 15,
  },
  profileContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  name: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#000',
  },
  description: {
    fontSize: 14,
    color: '#666',
    textAlign: 'center',
    marginVertical: 10,
    paddingHorizontal: 15,
  },
  themesContainer: {
    width: '100%',
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: 10,
  },
  hashtags: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  hashtag: {
    backgroundColor: '#333',
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 20,
    color: '#FFF',
    marginRight: 10,
    marginBottom: 10,
  },
  metricsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    marginVertical: 20,
  },
  metricCard: {
    backgroundColor: '#FDF05D',
    width: '45%',
    borderRadius: 10,
    padding: 20,
    marginBottom: 15,
    alignItems: 'center',
  },
  metricValue: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
  },
  metricLabel: {
    fontSize: 14,
    color: '#333',
    marginTop: 5,
  },
  postsContainer: {
    width: '100%',
    alignItems: 'center',
    marginVertical: 20,
  },
  postsCount: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#000',
  },
  postsFrequency: {
    fontSize: 14,
    color: '#666',
    marginTop: 5,
  },
  chartContainer: {
    marginVertical: 20,
    width: '100%',
    alignItems: 'center',
  },
  chartTitle: {
    fontSize: 18,
    color: '#000',
    marginBottom: 10,
  },
  barChart: {
    borderRadius: 10,
  },
  instagramLinkContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  instagramLink: {
    fontSize: 16,
    color: 'black',
    marginLeft: 10,
    textDecorationLine: 'underline',
  },
  errorText: {
    color: '#FF0000',
    fontSize: 18,
    textAlign: 'center',
    marginTop: 20,
  },
  backButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
  noDataContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noDataText: {
    fontSize: 18,
    color: '#000',
    textAlign: 'center',
    marginVertical: 10,
  },
  instagramLinkText: {
    fontSize: 16,
    color: '#000',
    textAlign: 'center',
    marginTop: 10,
  },
});

export default InfluencerAnalysis;
