import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Linking, Image, Alert } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Feather from 'react-native-vector-icons/Feather';

const AccountSettingsPage = ({ navigation }) => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetSessionData.php')
      .then(response => response.json())
      .then(data => {
        setUserData(data);
      })
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/EndSession.php');
      const data = await response.json();

      // Display logout status message
      if (data.success) {
        Alert.alert('Logout', 'Logout successful');
      } else {
        Alert.alert('Logout', 'No active session found');
      }

      // Reload the app after logout
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <View style={styles.container}>

      {/* Profile Section */}
      <View style={styles.profileSection}>
        <Image
          style={styles.profilePicture}
          source={{ uri: userData.ProfileImage }}
          resizeMode="cover"
        />
        <View style={styles.profileDetails}>
          <View style={styles.emailContainer}>
            <Ionicons name="mail-outline" size={20} color="black" />
            <Text style={styles.profileText}>{userData.UserEmail}</Text>
          </View>
          <View style={styles.businessNameContainer}>
            <Ionicons name="business-outline" size={20} color="black" />
            <Text style={styles.profileText}>{userData.BusinessName}</Text>
          </View>
          <View style={styles.businessAddressContainer}>
            <Ionicons name="location-outline" size={20} color="black" />
            <Text style={styles.profileText}>{userData.BusinessAddress}</Text>
          </View>
        </View>
      </View>

      <TouchableOpacity
        style={styles.settingContainer}
        onPress={() => Linking.openURL('https://collabbayadmin.com/UpdateProfile.html')} // Redirects to the Edit Profile URL
      >
        <Ionicons name="person-outline" size={24} color="black" />
        <Text style={styles.settingText}>Edit Profile</Text>
      </TouchableOpacity>

      <TouchableOpacity style={styles.settingContainer}>
        <Ionicons name="notifications-outline" size={24} color="black" />
        <Text style={styles.settingText}>Help</Text>
      </TouchableOpacity>

      <TouchableOpacity
    style={styles.settingContainer}
    onPress={() => Linking.openURL('https://collabbayadmin.com/plandetails')}
>
    <Ionicons name="card-outline" size={24} color="black" />
    <Text style={styles.settingText}>Billing</Text>
</TouchableOpacity>

      {/* Conditionally render the analytics button if user is an Admin */}
      {userData.Status === 'Admin' && (
        <TouchableOpacity
          style={styles.settingContainer}
          onPress={() => navigation.navigate('collabbayAnalytics')} // Redirects to the Analytics page
        >
          <Feather name="bar-chart" size={24} color="black" />
          <Text style={styles.settingText}>Analytics</Text>
        </TouchableOpacity>
      )}

      <TouchableOpacity style={styles.homeButton} onPress={() => navigation.navigate('BusinessHomepage')}>
        <Feather name="home" size={24} color="#000" />
      </TouchableOpacity>

      <TouchableOpacity style={styles.logoutButton} onPress={handleLogout}>
        <Text style={styles.logoutText}>Log Out</Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}>
        <Text style={styles.linkText}>By using Collabbay, you agree to our Privacy Policy.</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 30,
    color: '#333333',
  },
  profileSection: {
    alignItems: 'center',
    marginBottom: 30,
  },
  profilePicture: {
    width: 150,
    height: 150,
    borderRadius: 75,
    borderWidth: 5,
    borderColor: '#fff',
  },
  profileDetails: {
    alignItems: 'center',
    marginTop: 10,
  },
  emailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  businessNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  businessAddressContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  profileText: {
    marginLeft: 10,
    fontSize: 18,
    color: '#333333',
  },
  settingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    paddingHorizontal: 20,
    paddingVertical: 15,
    backgroundColor: '#F5F5F5',
    borderRadius: 15,
    width: '100%',
  },
  settingText: {
    marginLeft: 20,
    fontSize: 18,
    color: '#333333',
  },
  logoutButton: {
    backgroundColor: 'black',
    paddingVertical: 15,
    paddingHorizontal: 50,
    borderRadius: 15,
    marginTop: 30,
  },
  logoutText: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 'bold',
  },
  homeButton: {},
  linkText: {
    fontSize: 9,
    color: 'black',
    textDecorationLine: 'underline',
    marginTop: 25,
  },
});

export default AccountSettingsPage;
