import React, { useState, useEffect, useRef } from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { Camera } from 'expo-camera';
import { MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

const QRCodeScannerPage = () => {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [torchOn, setTorchOn] = useState(false);
  const cameraRef = useRef(null);
  const navigation = useNavigation();

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  const handleBarCodeScanned = ({ type, data }) => {
    if (!scanned) {
      setScanned(true);
      const scanType = getType(data);
      if (scanType === 'CustomerScan') {
        const CollabID = extractCollabID(data);
        if (CollabID) {
          navigation.navigate('PostScanAdminRedeemCustomer', { CollabID });
        } else {
          alert('CollabID parameter not found in URL');
        }
      } else if (scanType === 'PromoterScan') {
        const QRID = extractQRID(data);
        if (QRID) {
          navigation.navigate('PostScanAdminRedeem', { QRID });
        } else {
          alert('QRID parameter not found in URL');
        }
      } else {
        alert(`Unknown scan type: ${scanType}`);
      }
      setTimeout(() => setScanned(false), 2000);
    }
  };

  const extractQRID = (url) => {
    const match = url.match(/[?&]QRID=([^&]+)/);
    return match ? match[1] : null;
  };

  const extractCollabID = (url) => {
    const match = url.match(/[?&]CollabID=([^&]+)/);
    return match ? match[1] : null;
  };

  const getType = (url) => {
    const match = url.match(/[?&]type=([^&]+)/);
    return match ? match[1] : null;
  };

  const toggleTorch = () => {
    setTorchOn(!torchOn);
    if (cameraRef.current) {
      cameraRef.current
        .setFlashMode(torchOn ? 'off' : 'torch')
        .catch((e) => console.log(e));
    }
  }; 

  const navigateToHomepage = () => {
    navigation.navigate('BusinessHomepage');
  };

  if (hasPermission === null) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>Requesting camera permission</Text>
      </View>
    );
  }
  if (hasPermission === false) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>No access to camera</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Camera
        ref={cameraRef}
        style={styles.cameraContainer}
        type={Camera.Constants.Type.back}
        flashMode={torchOn ? 'torch' : 'off'}
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
      >
        <View style={styles.whiteSquare} />
      </Camera>
      <TouchableOpacity style={styles.homeButton} onPress={navigateToHomepage}>
        <Text style={styles.buttonText}>Go to Homepage</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.roundedBox} onPress={toggleTorch}>
        <MaterialIcons name={torchOn ? 'flash-off' : 'highlight'} size={24} color="black" />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cameraContainer: {
    flex: 1,
    ...StyleSheet.absoluteFillObject,
  },
  whiteSquare: {
    position: 'absolute',
    top: '30%',
    left: '20%',
    width: '60%',
    aspectRatio: 1,
    borderWidth: 5,
    borderColor: 'white',
    borderRadius: 15,
  },
  roundedBox: {
    position: 'absolute',
    bottom: '17.5%',
    width: 50,
    height: 50,
    backgroundColor: 'white',
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  homeButton: {
    position: 'absolute',
    bottom: 40,
    backgroundColor: '#FFDD00',
    padding: 10,
    borderRadius: 8,
  },
  buttonText: {
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: '#FFDD00',
  },
  text: {
    color: 'black',
    fontFamily: 'System',
    backgroundColor: 'white',
    borderRadius: 15,
    padding: 10,
    borderWidth: 1,
    borderColor: 'white',
    margin: 10,
  },
});

export default QRCodeScannerPage;
