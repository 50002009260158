import React, { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, View, Text, ScrollView, Linking, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const CreateChoice = ({ navigation }) => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    // Fetch user data when component mounts
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    // Make a request to your PHP file to retrieve user data based on session
    fetch('https://collabbayadmin.com/APIControllers/GetSessionData.php')
      .then(response => response.json())
      .then(data => {
        // Update the state with the retrieved user data
        setUserData(data);
      })
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const handleQRCodeScan = () => {
    Linking.openURL('https://collabbayadmin.com/QR.html');
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        {/* Dashboard Section */}
        <View style={styles.section}>
          {/* Second Row */}
          <View style={styles.cardRow}>
            {/* Create Promotion Offer Card */}
            <TouchableOpacity style={styles.fullWidthCard} onPress={() => navigation.navigate('CreateBrandListing')}>
              <Ionicons name="laptop" size={35} color="white" />
              <Text style={[styles.cardTitle, { color: 'white' }]}>Create New Promotion Offer</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>

      {/* Footer Section */}
      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={handleQRCodeScan}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>

          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessAccountPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>

        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessHomepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
          <Ionicons name="calendar" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CreateBrandListingChoice')}>
          <Ionicons name="add" size={24} color="black" />
        </TouchableOpacity>
      </View>
      
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingHorizontal: 10,
    alignItems: 'center',
    width: '95%',
    alignSelf: 'center',
    marginTop: 225,
  },
  section: {
    marginBottom: 20,
  },
  cardRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  fullWidthCard: {
    width: '100%',
    height: 150,
    backgroundColor: 'black',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    position: 'relative',
  },
  cornerTopLeft: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 10,
    height: 10,
    backgroundColor: 'black',
  },
  cornerTopRight: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 10,
    height: 10,
    backgroundColor: 'black',
  },
  cornerBottomLeft: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 10,
    height: 10,
    backgroundColor: 'black',
  },
  cornerBottomRight: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 10,
    height: 10,
    backgroundColor: 'black',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    textAlign: 'center',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffff',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
});

export default CreateChoice;
