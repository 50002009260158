import React, { useState, useEffect } from 'react';
import { ScrollView, Text, View, Image, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import { BarChart } from 'react-native-chart-kit';
import { FontAwesome5, MaterialIcons, Ionicons } from '@expo/vector-icons'; // Icons added
import { LinearGradient } from 'expo-linear-gradient'; // For gradient
import { useNavigation, useRoute } from '@react-navigation/native'; // Import useNavigation and useRoute for navigation

const screenWidth = Dimensions.get("window").width;

const CampaignMetrics = () => {
  const [data, setData] = useState(null);
  const navigation = useNavigation(); // Initialize navigation
  const route = useRoute(); // Access the route to get params
  const { ListingID } = route.params; // Extract ListingID from route params

  useEffect(() => {
    // Fetch data from the API using ListingID from route params
    fetch(`https://collabbayadmin.com/APIControllers/CampaignAnalytics.php?ListingID=${ListingID}`)
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [ListingID]);

  // Function to handle back press
  const handleBackPress = () => {
    navigation.goBack(); // Use navigation to go back
  };

  if (!data) {
    return (
      <Text style={{
        flex: 1,
        fontWeight: 'bold',
        fontSize: 18,
        textAlign: 'center',
        textAlignVertical: 'center'
      }}>
        Loading...
      </Text>
    );
  }

  const { viewsCount, redeemsCount, postUploads, promoters } = data;
  const estimatedReach = [viewsCount * 2, viewsCount * 40, viewsCount * 100];

  // Gradient bar calculation
  const gradientPercentage = Math.min((viewsCount - estimatedReach[0]) / (estimatedReach[2] - estimatedReach[0]), 1) * 100;

  // Chart Data for Performance
  const chartData = {
    labels: ["Views", "Redeems"],
    datasets: [
      {
        data: [viewsCount, redeemsCount]
      }
    ]
  };

  // Function to check if a promoter has content and get the PostURL
  const getPromoterPostURL = (promoter) => {
    const post = postUploads.find(p => p.Promoter === promoter); // Check Promoter instead of PlatformUsername
    return post ? post.PostURL : null;
  };

  return (
    <ScrollView style={styles.container}>
      {/* Back Button */}
      <View style={styles.backContainer}>
      <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
        <Ionicons name="arrow-back" size={24} color="black" />
      </TouchableOpacity>
    </View>
      {/* Estimated Reach Bar */}
      <Text style={styles.subTitle}>Estimated Reach Range</Text>
      <View style={styles.reachBar}>
        <LinearGradient
          colors={['#ecece7', '#aaaaa2', '#111110', '#aaaaa2', '#ecece7']}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          style={[styles.reachBarFilled, { width: `${gradientPercentage}%` }]}
        />
      </View>
      <View style={styles.reachMarkers}>
        <Text style={styles.markerText}>{viewsCount * 2} Views</Text>
        <Text style={styles.middleText}>{viewsCount * 40} Views</Text>
        <Text style={styles.markerText}>{viewsCount * 100} Views</Text>
      </View>

      {/* Views and Visits Display */}
      <View style={styles.metricsContainer}>
        <View style={styles.metricBox}>
          <FontAwesome5 name="eye" size={22} color="black" />
          <Text style={styles.metricValue}>{viewsCount}</Text>
          <Text style={styles.metricLabel}>Clicks</Text>
        </View>
        <View style={styles.metricBox}>
          <MaterialIcons name="person-pin-circle" size={22} color="black" />
          <Text style={styles.metricValue}>{redeemsCount}</Text>
          <Text style={styles.metricLabel}>Visits</Text>
        </View>
      </View>


      <View style={{ flex: 1, marginTop: 2, marginBottom:2, justifyContent: 'center', alignItems: 'center' }}>
      <Text style={{ textAlign: 'center', fontSize: 9, color: 'black' }}>
        'Views' are estimated based on clicks of the collab URL posted by the influencer, to see views on the respective social media platform, click 'view content' below. 'Visits' refers to customer visits that have occurred as a result of the collab, tracked by how many customer QR codes you have scanned.
      </Text>
    </View>

      {/* Promoters Section */}
      <Text style={styles.subTitle}>Promoters</Text>
      {promoters.map((promoter, index) => {
        const postURL = getPromoterPostURL(promoter.Promoter); // Corrected logic
        return (
          <View key={index} style={styles.promoterCard}>
            <Image source={{ uri: promoter.ProfileImage }} style={styles.promoterImage} />
            <View style={styles.promoterInfo}>
              <TouchableOpacity onPress={() => window.open(`https://instagram.com/${promoter.PlatformUsername}`, '_blank')}>
                <Text style={styles.promoterUsername}>@{promoter.PlatformUsername}</Text>
              </TouchableOpacity>
              <Text style={styles.followersText}>{promoter.PlatformFollowers ? `${promoter.PlatformFollowers} Followers` : 'No data'}</Text>
            </View>
            <View style={styles.postUrlContainer}>
              {postURL && (
                <TouchableOpacity onPress={() => window.open(postURL, '_blank')} style={styles.linkButton}>
                  <MaterialIcons name="link" size={20} color="black" />
                  <Text style={styles.linkText}>View Content</Text>
                </TouchableOpacity>
              )}
            </View>
            <TouchableOpacity
              onPress={() => window.open(`https://instagram.com/${promoter.PlatformUsername}`, '_blank')}
              style={styles.viewPageButton}
            >
              <MaterialIcons name="launch" size={20} color="black" />
              <Text style={styles.viewPageText}>View Page</Text>
            </TouchableOpacity>
          </View>
        );
      })}

      {/* Content Section */}
      <Text style={styles.subTitle}>Content</Text>
      {postUploads.map((post, index) => (
        <View key={index} style={styles.contentCard}>
          <Image source={{ uri: post.Image }} style={styles.contentImage} />
          <Text style={styles.contentPromoterText}>Promoter: {post.Promoter}</Text>
          <Text style={styles.contentText}>Submitted: {post.DateTimeUploaded || 'N/A'}</Text>
          {post.PostURL && (
            <TouchableOpacity onPress={() => window.open(post.PostURL, '_blank')} style={styles.viewContentButton}>
              <MaterialIcons name="link" size={20} color="black" />
              <Text style={styles.linkText}>View Content</Text>
            </TouchableOpacity>
          )}
        </View>
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: '#fff',
    flex: 1,
  },
  backButton: {
    position: 'absolute',
    top: 20,
    left: 10,
    zIndex: 1,
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    textAlign: 'center',
    marginVertical: 15,
    color: '#333',
  },
  backContainer: {
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 0,
    padding: 20,
  },
  metricsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
  metricBox: {
    alignItems: 'center',
    flex: 1,
  },
  metricValue: {
    fontSize: 36,
    fontWeight: 'bold',
    color: '#333',
  },
  smallMetricValue: {
    fontSize: 21,
    fontWeight: 'bold',
    color: '#333',
  },
  metricLabel: {
    fontSize: 14,
    color: '#888',
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginVertical: 10,
    color: '#333',
  },
  reachBar: {
    width: '100%',
    height: 15, // Thinner bar
    borderRadius: 12,
    overflow: 'hidden',
    marginTop: 10,
  },
  reachBarFilled: {
    height: '100%',
  },
  reachMarkers: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
  },
  markerText: {
    fontSize: 12,
    color: '#888',
  },
  promoterCard: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
    marginVertical: 8,
    elevation: 2,
  },
  promoterImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
  promoterInfo: {
    flex: 1,
  },
  promoterUsername: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#333',
  },
  followersText: {
    fontSize: 12,
    color: '#888',
  },
  postUrlContainer: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: 10,
  },
  linkButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  viewPageButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  viewPageText: {
    marginLeft: 4,
    color: 'black',
    fontSize: 11,
  },
  viewContentButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  linkText: {
    marginLeft: 4,
    color: 'black',
  },
  contentCard: {
    padding: 10,
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
    marginVertical: 8,
    elevation: 2,
  },
  contentImage: {
    width: '100%',
    height: 200,
    borderRadius: 8,
    marginBottom: 10,
  },
  contentPromoterText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#333',
  },
  contentText: {
    fontSize: 12,
    color: '#666',
  }
});

export default CampaignMetrics;
