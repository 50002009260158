import React from 'react';
import { 
  SafeAreaView, 
  StyleSheet, 
  View, 
  Text, 
  ScrollView, 
  Image, 
  TouchableOpacity,
  Alert
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const ReviewCollabOfferFormPage = ({ route, navigation }) => {
  
  const handleBackPress = () => {
    navigation.goBack();
  };

  const handleCollabPress = () => {
    const { CollabID } = route.params;
    fetch('https://collabbayadmin.com/APIControllers/InsertApprovedCollab.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ CollabID: CollabID })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      Alert.alert('Success', 'Collab status updated successfully');
    })
    .catch(error => {
      console.error('There was an error!', error);
      Alert.alert('Error', 'Failed to update collab status');
    });
  };
  
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        {/* Trendy Slide Section */}
        <View style={styles.trendyBanner}>
          <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
            <Ionicons name="arrow-back" size={28} color="white" />
          </TouchableOpacity>
          <Image
            style={styles.trendyImage}
            source={{ uri: 'https://images.pexels.com/photos/1235746/pexels-photo-1235746.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' }}
            resizeMode="cover"
          />
        </View>

        {/* Profile Section */}
        <View style={styles.profileSection}>
          <Image
            style={styles.profilePicture}
            source={{ uri: 'https://images.pexels.com/photos/3030332/pexels-photo-3030332.jpeg?auto=compress&cs=tinysrgb&w=300' }}
            resizeMode="cover"
          />
          <View style={styles.profileDetails}>
            <View style={styles.usernameContainer}>
              <Text style={styles.usernameText}>@che</Text>
            </View>
            <Text style={styles.name}>Che Guevara</Text>
            <Text style={styles.bio}>Revolutionary | Guerrilla Leader</Text>
            <Text style={styles.age}>39</Text>
          </View>
        </View>

        {/* Text Section */}
        <View style={styles.textSection}>
          <View style={styles.textBackground}>
            <Text style={styles.text}>
              Lorem ipsum dolor sit
            </Text>
          </View>
        </View>

        {/* Interest Section */}
        <View style={styles.section}>
          <View style={styles.interestContainer}>
            {['Technology', 'Travel', 'Food', 'Politics', 'Art'].map((interest, index) => (
              <TouchableOpacity key={index} style={styles.interest}>
                <Text style={styles.interestText}>{interest}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>

        {/* Collab Button */}
        <TouchableOpacity style={styles.button} onPress={handleCollabPress}>
          <Text style={styles.buttonText}>Collab</Text>
        </TouchableOpacity>
      </ScrollView>

      {/* Footer */}
      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon}>
          <Ionicons name="qr-code" size={24} color="black" onPress={() => navigation.navigate('UserQRDashboard')}/>
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('UserRecapPage')}>
          <Ionicons name="person" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('Homepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon}>
          <Ionicons name="gift" size={24} color="black" />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingBottom: 60,
    paddingHorizontal: 10,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  section: {
    marginBottom: 20,
  },
  interestContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  interest: {
    backgroundColor: '#f0f0f0',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20,
    marginBottom: 10,
    marginHorizontal: 5,
    alignItems: 'center',
  },
  interestText: {
    fontSize: 16,
  },
  profileSection: {
    position: 'relative',
  },
  profilePicture: {
    width: 150,
    height: 150,
    borderRadius: 75,
    position: 'absolute',
    top: -75,
    left: '50%',
    marginLeft: -75,
    borderWidth: 5,
    borderColor: '#fff',
  },
  profileDetails: {
    marginTop: 100,
    alignItems: 'center',
  },
  usernameContainer: {
    backgroundColor: 'black',
    borderRadius: 20,
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  usernameText: {
    fontSize: 20,
    fontWeight: 'bold',
    width: '50%',
    color: 'white',
  },
  name: {
    fontSize: 18,
    paddingTop: 2,
    color: '#666',
  },
  bio: {
    paddingTop: 2,
    marginBottom: 5,
  },
  age: {
    fontSize: 18,
    color: '#666',
    fontWeight: 'bold',
    paddingBottom: 10,
  },
  trendyBanner: {
    marginBottom: 20,
    borderRadius: 10,
    overflow: 'hidden',
  },
  trendyImage: {
    width: '100%',
    height: 150,
  },
  textSection: {
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  textBackground: {
    backgroundColor: 'black',
    padding: 20,
    borderRadius: 10,
  },
  text: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
    lineHeight: 22,
  },
  backButton: {
    position: 'absolute',
    top: 9,
    left: 9,
    zIndex: 1,
    padding: 10,
    backgroundColor: 'transparent',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffff',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'green',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    borderRadius: 10,
    position: 'absolute',
    bottom: 20,    
    left: 20,
    right: 20,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
});

export default ReviewCollabOfferFormPage;
