import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View, Text, ScrollView, Image, TextInput, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const PotentialCollabsDashboardView = ({ navigation }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/RetrievePotentialCollabs.php');
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.searchBar}>
        <TextInput
          style={styles.searchInput}
          placeholder="Search..."
          placeholderTextColor="#666"
        />
      </View>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        {/* Dashboard Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Promotion Submissions</Text>
          {/* Render data */}
          {data.map((item, index) => (
            <TouchableOpacity 
              style={styles.card} 
              key={index} 
              onPress={() => navigation.navigate('ReviewCollabOfferForm', { CollabID: item.CollabID })}

            >
              <View style={styles.cornerTopLeft} />
              <View style={styles.cornerTopRight} />
              <View style={styles.cornerBottomLeft} />
              <View style={styles.cornerBottomRight} />
              <Image style={styles.logo} source={{ uri: item.imageUri }} />
              <Text style={styles.cardTitle}>@{item.BrandName}</Text>
              <Text style={styles.cardSubText}>{item.CollabID}</Text>
            </TouchableOpacity>
          ))}
        </View>
      </ScrollView>

      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon}>
          <Ionicons name="qr-code" size={24} color="black" onPress={() => navigation.navigate('UserQRDashboard')} />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('UserRecapPage')}>
          <Ionicons name="person" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessHomepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon}>
          <Ionicons name="cash" size={24} color="black" />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  searchBar: {
    backgroundColor: '#fff',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  searchInput: {
    backgroundColor: 'black',
    color: 'white',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 20,
    width: '100%',
  },
  contentContainer: {
    paddingHorizontal: 10,
    alignItems: 'center',
    width: '95%',
    alignSelf: 'center',
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    padding: 5,
  },
  card: {
    width: '100%',
    minWidth: '100%',
    height: 120,
    backgroundColor: '#f0f0f0',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    position: 'relative', // Add this to position corners relative to the card
    marginBottom: 10,
  },
  logo: {
    width: 30,
    height: 30,
    marginBottom: 5,
    borderRadius: 150,
    padding: 10,
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  cardSubText: {
    fontSize: 12,
    color: '#666',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffff',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
});

export default PotentialCollabsDashboardView;
