import React, { useState } from 'react';
import { Text, View, TextInput, Button, Alert, StyleSheet, TouchableOpacity } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';

const PasswordResetForm = ({ navigation }) => {
  const [userEmail, setUserEmail] = useState('');

  const handleResetPassword = async () => {
    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/PasswordResetToken.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ UserEmail: userEmail }),
      });
      const data = await response.json();
      if (response.ok) {
        Alert.alert('Success', 'Password reset token sent successfully.');
      } else {
        Alert.alert('Error', data.message || 'Something went wrong.');
      }
    } catch (error) {
      console.error('Error:', error);
      Alert.alert('Error', 'Something went wrong.');
    }
  };

  return (
    <View style={styles.container}>
      {/* Back Button */}
      <TouchableOpacity style={styles.backButton} onPress={() => navigation.goBack()}>
        <Feather name="arrow-left" size={24} color="#000" />
      </TouchableOpacity>

      <TextInput
        style={styles.textInput}
        placeholder="Enter your email"
        value={userEmail}
        onChangeText={(text) => setUserEmail(text)}
      />


<TouchableOpacity style={styles.resetButton} onPress={handleResetPassword}>
      <Text style={styles.resetButtonText}>Reset Password</Text>
    </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 20,
  },
  input: {
    width: '100%',
    height: 40,
    marginBottom: 20,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: '#000000',
    borderRadius: 5,
  },
  backButton: {
    position: 'absolute',
    top: 40,
    left: 20,
  },
  textInput: {
    width: '80%',
    height: 50,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginBottom: 20,
    fontSize: 16,
    paddingHorizontal: 10,
  },
  resetButton: {
    backgroundColor: 'black',
    width: '80%',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  resetButtonText: {
    color: 'white', // Making text color white
    textAlign: 'center', // Centering text within the button
  },

});

export default PasswordResetForm;
