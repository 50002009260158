import React, { useEffect, useState } from 'react';
import { View, Text, ActivityIndicator, TextInput, TouchableOpacity, Alert } from 'react-native';
import { useParams } from 'react-router-dom';

const ViewTracker = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userPassword, setUserPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { token } = useParams(); // Extract token from URL
  const [linkExpired, setLinkExpired] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        // Fetch user data
        const response = await fetch(`https://collabbayadmin.com/APIControllers/ChangePasswordAuthoriser.php?token=${token}`);
        const data = await response.json();
        
        if (data) {
          // Check conditions before setting userData
          const currentDate = new Date();
          const userDataDate = new Date(data.DateTime);
          const timeDifference = (currentDate - userDataDate) / (1000 * 60); // Difference in minutes

          if (timeDifference > 15 || data.Status !== 'Usable') {
            setLinkExpired(true);
            setLoading(false);
            return;
          }

          setUserData(data);
          setLoading(false);
        } else {
          console.error('No data found for token:', token);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchUserData(); // Fetch user data
  }, [token]);

  const handleResetPassword = async () => {
    if (userPassword !== confirmPassword) {
      Alert.alert('Passwords do not match');
      return;
    }

    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/UpdateUserPasswordInsert.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userEmail: userData.UserEmail,
          userPassword: userPassword,
          token: token, // Add the token field and set its value
        }),
      });
      const data = await response.json();
      if (response.ok) {
        Alert.alert('Reset Submitted');
        setUserPassword('');
        setConfirmPassword('');
      } else {
        Alert.alert('Failed to reset password');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      Alert.alert('Failed to reset password');
    }
  };

  return (
    <View style={{ backgroundColor: 'black', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text style={{ color: 'white', fontSize: 24 }}>collabbay.com</Text>
      {loading ? (
        <ActivityIndicator size="large" color="#ffffff" />
      ) : (
        <>
          {linkExpired ? (
            <Text style={{ color: 'red', fontSize: 16 }}>Link Expired</Text>
          ) : (
            <>
              {token && (
                <Text style={{ color: 'white', fontSize: 16 }}>Token: {token}</Text>
              )}
              {userData && (
                <>
                  <Text style={{ color: 'white', fontSize: 16 }}>UserEmail: {userData.UserEmail}</Text>
                  <Text style={{ color: 'white', fontSize: 16 }}>Token: {userData.Token}</Text>
                  <TextInput
                    style={{ backgroundColor: 'white', marginVertical: 10, padding: 10, width: 200 }}
                    placeholder="Enter New Password"
                    value={userPassword}
                    onChangeText={setUserPassword}
                    secureTextEntry
                  />
                  <TextInput
                    style={{ backgroundColor: 'white', marginVertical: 10, padding: 10, width: 200 }}
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChangeText={setConfirmPassword}
                    secureTextEntry
                  />
                  <TouchableOpacity style={{ backgroundColor: 'blue', padding: 10, marginTop: 10 }} onPress={handleResetPassword}>
                    <Text style={{ color: 'white', textAlign: 'center' }}>Reset Password</Text>
                  </TouchableOpacity>
                </>
              )}
            </>
          )}
        </>
      )}
    </View>
  );
};

export default ViewTracker;
